export default {
  upper(value){
    return (value) ? value.toUpperCase() : ''
  },

  lower(value){
    return (value) ? value.toLowerCase() : ''
  },

  local(value){
    return (value) ? value.toLocaleString() : ''
  }

}
