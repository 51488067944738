/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const title = 'stone'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  routes: [
    {
      path: '/',
      name: 'top',
      meta: {
        title: `${title}`
      },
      component: () => import('../views/Top.vue')

    }, {
      path: '/event',
      component: () => import('../views/Event.vue'),
      children: [
        {
          path: ':id',
          name: 'event',
          component: () => import('../components/template/EventMain.vue'),
          meta: {
            title: `event | ${title}`
          }
        },
        {
          path: ':id/content/:content_id',
          name: 'eventContent',
          component: () => import('../components/template/Content.vue'),
          meta: {
            title: `event | ${title}`
          }
        },
        {
          path: 'integrated/:id',
          name: 'eventIntegrated',
          component: () => import('../components/template/EventIntegrated.vue'),
          meta: {
            title: `event | ${title}`
          }
        },
        {
          path: 'embedded/:id',
          name: 'eventEmbedded',
          component: () => import('../components/template/EventEmbedded.vue'),
          meta: {
            title: `event | ${title}`
          }
        }
      ]
    }, {
      path: '/order/:token/',
      name: 'order',
      meta: {
        title: `order | ${title}`
      },
      component: () => import('../views/Order.vue')

    }, {
      path: '/reset/:event_id/:token/',
      name: 'reset',
      meta: {
        title: `パスワード再設定 | ${title}`
      },
      component: () => import('../views/Reset.vue')
    }, {
      path: '/private/',
      name: 'private',
      meta: {
        title: `イベント非公開 | ${title}`
      },
      component: () => import('../views/Private.vue')

    }, {
      path: '/404',
      name: 'notfound',
      meta: {
        title: `404 - ${title}`,
        authenticationExclude: true
      },
      component: () => import('../views/NotFound.vue')

    }, {  
      path    : '*',
      redirect: {name: 'notfound'}
    }
  ],
  scrollBehavior(to, from, savedPosition){
    return { x:0, y: 0 }
  }
})

router.afterEach((to , from) => {
  if(to.meta && to.meta.title){
    document.title = to.meta.title
  }
})

export default router
