export default{
  // elepay設定
//  elepay_paid : "http://192.168.80.153:8002/event/",
//  elepay_order : "http://192.168.80.153:8002/order/",
  elepay_paid : "https://stone.test.dev.ncv.jp/event/",
  elepay_order : "https://stone.test.dev.ncv.jp/order/",
//  elepay_paid : "https://stone.dev.ncv.jp/event/",
//  elepay_order : "https://stone.dev.ncv.jp/order/",
  // デフォルトサムネイル
  event_thumbnail : "https://buckets.dev.ncv.jp/stone2/content_default_thumbnail.png"
}

