/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import Vue from 'vue'
import Vuex from 'vuex'

//import createPersistedState from 'vuex-persistedstate'

import where from '@where/client';

import snackbar from './snackbar'

Vue.use(Vuex)

//function getLocalStorageCheck(state){
//  const setKey = 'stoneMain' + state.route.params.id
//  const obj = JSON.parse(localStorage.getItem(setKey))
//  
//  return obj ? obj : ''
//}

const store = new Vuex.Store({
  
  plugins: [
    //createPersistedState()
  ],
  modules: {
    snackbar: snackbar
  },
  state: {
    buy : false,
    eventId : null,
    hash : null,
    item : null,
  },

  getters: {

    getBuy: state => {
      //const obj = getLocalStorageCheck(state)
      //return obj ? state.buy : ''
      return state.buy
    },
    
    getEventId : state => {
      return state.getEventId
    },
    
    getHash : state => {
      return state.hash
    },
    
    getItem : state => {
      return state.item
    },

  },

  mutations: {
    set(state, credential){
      Object.entries(credential).forEach(([k, v]) => {
        state[k] = v
      })
      const setKey = 'stoneMain' + state.route.params.id
      localStorage.setItem(setKey, JSON.stringify(state))
    },

    restore(state){
      
      const setKey = 'stoneMain' + state.route.params.id

      if (localStorage.getItem(setKey)) {
      
        const store = JSON.parse(localStorage.getItem(setKey))

        const obj = Object.assign({}, store)
        delete obj.route

        this.replaceState(Object.assign(state, obj))
      }
    },

  },

  actions: {
    set(context, credential){
      context.commit('set', credential)
    },

    restore(context){
      context.commit('restore')
    },
  },

})

export default store
