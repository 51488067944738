import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import router from './router'
import store from './store'
import mixin from './mixins/index'

import { sync } from 'vuex-router-sync'

// vue-cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import filter from '@/lib/filter'
Vue.mixin(mixin)

Vue.config.productionTip = false

sync(store, router);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Object.entries(filter).forEach(([k, v]) => {
    Vue.filter(k, v)
})

