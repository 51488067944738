const getters = {
  getMessage: (state) => {
    return state.message
  },
  getIsSnackbarShow: (state) => {
    return state.isShow
  }
}

const state = () => ({
  message: 'aaaaa',
  isShow: false
})

const mutations = {
  setSnackbar: (state, message) => {
    state.message = message
    state.isShow = true
  },
  unsetSnackbar: () => {
    state.isShow = false
    state.message = ''
  }
}

const actions = {
  openSnackbar: ({ commit }, message) => {
    commit('setSnackbar', message)
  },
  closeSnackbar: ({ commit }) => {
    commit('unsetSnackbar')
  }
}

export default{
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
