<template>
  <v-app>
    <v-main>
      <div>
        <!-- ページ遷移 -->
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
};
</script>

<style>
</style>
