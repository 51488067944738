import where from '@where/client'
import validationConfig from '../config/validationConfig'
import otherConfig from '../config/otherConfig'
import general from '@/config/index'

const config = {
  validation : validationConfig,
  other : otherConfig,
  ...general
}

export default {
  data(){
    return {
      config : config,
      wc: where.client(config.stone)
    }
  },
}
