const tel = (v) => {
      const pattern = /^0[0-9]{1,3}-*[0-9]{2,4}-*[0-9]{2,4}$/
      return pattern.test(v) || '不正な電話番号形式です'
}
const mail = (v) => {
  const pattern = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(v) || '不正なメールアドレス形式です'
}

const required = (v) => !!v || '必須項目です'

const maxStr = (v, num) => (v || '').length <= num || `最大${num}文字までです`

export default{
  tel,
  mail,
  required,
  maxStr
}
